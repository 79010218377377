/* eslint-disable */

import React, {useState, useEffect} from 'react'
import './addCardComponent.css'
import first from '../../assets/12.png'
import second from '../../assets/22.png'
import IMask from 'imask'
import visa from '../../assets/icons payment/visa.svg'
import MC from '../../assets/icons payment/Mastercard-logo.svg'

const AddCardComponent = () => {
    const [name, setName] = useState('')
    const [cardNumber, setCardNumber] = useState('')
    const [expDateMonth, setExpDateMonth] = useState('')
	const [expDateYear, setExpDateYear] = useState('')
    const [cvv, setCvv] = useState('')
    const [output, setOutput] = useState('')
    const [ccicon, setccIcon] = useState(second)
    const [generatedCard, setGeneratedCard] = useState('')

    const [cardBC, setCardBC] = useState(first)
    const [preloadClass, setPreLoadClass] = useState('container preload')
    const [flippedClass, setFlippedClass] = useState('creditcard')
    const [backCardColor, setBackCardColor] = useState('linear-gradient(to right, #026fad, #026fad 50%, #2F98D3 90%)')

    var mask=[
        {regex: '^3[47]\\d{0,13}', cardtype: 'american express'},
        {regex: '^(?:6011|65\\d{0,2}|64[4-9]\\d?)\\d{0,12}', cardtype: 'discover'},
        {regex: '^3(?:0([0-5]|9)|[689]\\d?)\\d{0,11}', cardtype: 'diners'},
        {regex: '^(5[1-5]\\d{0,2}|22[2-9]\\d{0,1}|2[3-7]\\d{0,2})\\d{0,12}', cardtype: 'mastercard'},
        {regex: '^(?:2131|1800)\\d{0,11}', cardtype: 'jcb15'},
        {regex: '^(?:35\\d{0,2})\\d{0,12}', cardtype: 'jcb'},
        {regex: '^(?:5[0678]\\d{0,2}|6304|67\\d{0,2})\\d{0,12}', cardtype: 'maestro'},
        {regex: '^4\\d{0,15}', cardtype: 'visa'},
        {regex: '^62\\d{0,14}', cardtype: 'unionpay'},
    ];

    const getTupe = (item) => {
        let result = mask.filter(inner=> item.match(inner.regex))
        return result?.length>0? result.slice(0,1).shift()?.cardtype:'Unknown'
    }
    useEffect(()=> {
        switch (getTupe(cardNumber)) {
            case 'american express':
                setccIcon('')
                setCardBC(second);
                break;
            case 'visa':
                setccIcon(visa)
                setCardBC(first);
                break;
            case 'diners':
                setccIcon('');
                setCardBC(second);
                break;
            case 'discover':
                setccIcon('');
                setCardBC(first);
                break;
            case ('jcb' || 'jcb15'):
                setccIcon('');
                setCardBC(second);
                break;
            case 'maestro':
                setccIcon('');
                setCardBC(first);
                break;
            case 'mastercard':
                setccIcon(MC);
                setCardBC(second);
                break;
            case 'unionpay':
                setccIcon('');
                setCardBC(first);
                break;
            default:
                setccIcon('');
                setCardBC(second);
                break;
        }
    }, [cardNumber])
  return (
    <div className='addCardComponent'>
    <div onClick={() => {setPreLoadClass('container'); flippedClass==='creditcard flipped'? setFlippedClass('creditcard'):setFlippedClass('creditcard flipped')}} className={preloadClass}>
		<div className={flippedClass}>
			<div style={{backgroundImage:`url(${cardBC})`, backgroundSize:'cover'}}  className="front">
				<div id="ccsingle"></div>
					<svg style={{fill:'transparent'}}  version="1.1" id="cardfront" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
					x="0px" y="0px" viewBox="0 0 750 471"  xmlSpace="preserve">
                        <g  id="Front">
							<g id="CardBackground">
								<g id="Page-1_1_">
									<g id="amex_1_">
										<path id="Rectangle-1_1_" className="lightcolor grey" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40C0,17.9,17.9,0,40,0z" />
									</g>
								</g>
								<path className="darkcolor greydark" d="M750,431V193.2c-217.6-57.5-556.4-13.5-750,24.9V431c0,22.1,17.9,40,40,40h670C732.1,471,750,453.1,750,431z" />
								</g>
								<text transform="matrix(1 0 0 1 60.106 295.0121)" id="svgnumber" className="st2 st3 st4">{cardNumber? cardNumber.slice(0,4) + ' ' + cardNumber.slice(4,8) + ' ' + cardNumber.slice(8,12) + ' ' + cardNumber.slice(12): '#### #### #### ####' }</text>
								<text transform="matrix(1 0 0 1 54.1064 428.1723)" id="svgname" className="st2 st5 st6">{name}</text>
								<text transform="matrix(1 0 0 1 54.1074 389.8793)" className="st7 st5 st8">cardholder name</text>
								<text transform="matrix(1 0 0 1 479.7754 388.8793)" className="st7 st5 st8">expiration</text>
								<text transform="matrix(1 0 0 1 65.1054 241.5)" className="st7 st5 st8">card number</text>
								<g>
									<text transform="matrix(1 0 0 1 574.4219 433.8095)" id="svgexpire" className="st2 st5 st9">{expDateMonth + '/' + expDateYear}</text>
									<text transform="matrix(1 0 0 1 479.3848 417.0097)" className="st2 st10 st11">VALID</text>
									<text transform="matrix(1 0 0 1 479.3848 435.6762)" className="st2 st10 st11">THRU</text>
									<polygon className="st2" points="554.5,421 540.4,414.2 540.4,427.9 		" />
								</g>
								<g id="cchip">
									<g>
										<path className="st2" d="M168.1,143.6H82.9c-10.2,0-18.5-8.3-18.5-18.5V74.9c0-10.2,8.3-18.5,18.5-18.5h85.3
									    c10.2,0,18.5,8.3,18.5,18.5v50.2C186.6,135.3,178.3,143.6,168.1,143.6z" />
									</g>
									<g>
										<g>
											<rect x="82" y="70" className="st12" width="1.5" height="60" />
										</g>
										<g>
											<rect x="167.4" y="70" className="st12" width="1.5" height="60" />
										</g>
										<g>
										    <path className="st12" d="M125.5,130.8c-10.2,0-18.5-8.3-18.5-18.5c0-4.6,1.7-8.9,4.7-12.3c-3-3.4-4.7-7.7-4.7-12.3
										    c0-10.2,8.3-18.5,18.5-18.5s18.5,8.3,18.5,18.5c0,4.6-1.7,8.9-4.7,12.3c3,3.4,4.7,7.7,4.7,12.3
										    C143.9,122.5,135.7,130.8,125.5,130.8z M125.5,70.8c-9.3,0-16.9,7.6-16.9,16.9c0,4.4,1.7,8.6,4.8,11.8l0.5,0.5l-0.5,0.5
										    c-3.1,3.2-4.8,7.4-4.8,11.8c0,9.3,7.6,16.9,16.9,16.9s16.9-7.6,16.9-16.9c0-4.4-1.7-8.6-4.8-11.8l-0.5-0.5l0.5-0.5
										    c3.1-3.2,4.8-7.4,4.8-11.8C142.4,78.4,134.8,70.8,125.5,70.8z" />
										</g>
										<g>
											<rect x="82.8" y="82.1" className="st12" width="25.8" height="1.5" />
										</g>
										<g>
											<rect x="82.8" y="117.9" className="st12" width="26.1" height="1.5" />
										</g>
										<g>
											<rect x="142.4" y="82.1" className="st12" width="25.8" height="1.5" />
										</g>
										<g>
											<rect x="142" y="117.9" className="st12" width="26.2" height="1.5" />
										</g>
									</g>
								</g>
							</g>
							<g id="Back">
							</g>
							</svg>
						</div>
						<div style={{background: backCardColor}} className="back">
						    <svg version="1.1" id="cardback" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
						    	x="0px" y="0px" viewBox="0 0 750 471" style={{enableBackground:"new 0 0 750 471"}} xmlSpace="preserve">
						    	<g id="Front">
						    		<line className="st0" x1="35.3" y1="10.4" x2="36.7" y2="11" />
						    	</g>
						    	<g id="Back">
						    		<g id="Page-1_2_">
						    			<g id="amex_2_">
						    				<path id="Rectangle-1_2_" className="darkcolor greydark" d="M40,0h670c22.1,0,40,17.9,40,40v391c0,22.1-17.9,40-40,40H40c-22.1,0-40-17.9-40-40V40
						    		C0,17.9,17.9,0,40,0z" />
						    			</g>
						    		</g>
						    		<rect y="61.6" className="st2" width="750" height="78" />
						    		<g>
						    			<path className="st3" d="M701.1,249.1H48.9c-3.3,0-6-2.7-6-6v-52.5c0-3.3,2.7-6,6-6h652.1c3.3,0,6,2.7,6,6v52.5
						    	C707.1,246.4,704.4,249.1,701.1,249.1z" />
						    			<rect x="42.9" y="198.6" className="st4" width="664.1" height="10.5" />
						    			<rect x="42.9" y="224.5" className="st4" width="664.1" height="10.5" />
						    			<path className="st5" d="M701.1,184.6H618h-8h-10v64.5h10h8h83.1c3.3,0,6-2.7,6-6v-52.5C707.1,187.3,704.4,184.6,701.1,184.6z" />
						    		</g>
						    		<text transform="matrix(1 0 0 1 621.999 227.2734)" id="svgsecurity" className="st6 st7">{cvv}</text>
						    		<g className="st8">
						    			<text transform="matrix(1 0 0 1 518.083 280.0879)" className="st9 st6 st10">security code</text>
						    		</g>
						    		<rect x="58.1" y="378.6" className="st11" width="375.5" height="13.5" />
						    		<rect x="58.1" y="405.6" className="st11" width="421.7" height="13.5" />
						    	</g>
						    </svg>
						</div>
					</div>
				</div>
				<div className="form-container">
					<div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}} className="field-container">
						<label style={{margin:'0px'}}>Card Holder</label>
						<input onFocus={()=> setFlippedClass('creditcard')} onChange={(e) => setName(e.target.value)} value={name} name='name' id="name" maxLength="20" type="text"/>
					</div>
					<div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}} className="field-container">
					    <label style={{margin:'0px'}}>Card Number</label>
						<input onFocus={()=> setFlippedClass('creditcard')} onChange={(e) => setCardNumber(e.target.value.replace(/\D/, ""))} maxLength='16' value={cardNumber} name='cardNumber' id="cardnumber" type="text" pattern="[0-9]*" inputMode="numeric"/>
						{ccicon !== ''? <img id="ccicon" className="ccicon" src={ccicon} alt='logos'/>:null}
					</div>
					<div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}} className="field-container">
					<label style={{margin:'0px'}}>Expiration Date</label>
					    <div style={{display:'flex', alignItems:'flex-start', justifyContent:'center', gap:'10px'}} >
					        <input maxLength='2' onFocus={()=> setFlippedClass('creditcard')} onChange={(e) => setExpDateMonth(e.target.value.replace(/\D/, ""))} value={expDateMonth} name='expDateMonth' placeholder="mm" id="expirationdate" type="text" pattern="[0-9]*" inputMode="numeric"/>
						    <input maxLength='2' onFocus={()=> setFlippedClass('creditcard')} onChange={(e) => setExpDateYear(e.target.value.replace(/\D/, ""))} value={expDateYear} name='expDateYear' placeholder="yy" id="expirationdate" type="text" pattern="[0-9]*" inputMode="numeric"/>
					    </div>
					</div>
					<div style={{display:'flex', flexDirection:'column', alignItems:'flex-start'}} className="field-container">
					    <label style={{margin:'0px'}}>CVV</label>
						<input onChange={(e) => setCvv(e.target.value.replace(/\D/, ""))} value={cvv} name='cvv' onFocus={()=> setFlippedClass('creditcard flipped')} id="securitycode" type="text" maxLength='3' pattern="[0-9]*" inputMode="numeric"/>
					</div>
				</div>
				<button type='button' className='creditCardFormButton'>Submit</button>

    </div>
  )
}

export default AddCardComponent