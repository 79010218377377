import React from 'react'
import './Footer.css'
import footerLogo from '../../assets/footerLogo.svg'
import languageIcon from '../../assets/languageIcon.svg'
import PCI from '../../assets/PCI.svg'

const Footer = () => {

  return (
    <footer>
        <div className='upper_footer'>
            <div className='upper_footer_section'>
                <img src={footerLogo} alt='footer_logo' className='footer_logo'/>
                <div className='language_wraper'>
                    <img src={languageIcon} alt='language_icon' className='language_Icon'/>
                    <a href='/' className='upper_footer_link'>English (United States)</a>
                </div>
            </div>
            <div className='upper_footer_section'>
                <label className='upper_footer_link'>Products</label>
                <ul>
                    <li>
                        <a href='/online-payments' className='upper_footer_link'>Online Payments</a>
                    </li>
                    {/* <li>
                        <a href='/' className='upper_footer_link'>Point of Sale (POS)</a>
                    </li> */}
                </ul>
            </div>
            <div className='upper_footer_section'>
                <label className='upper_footer_link'>Developers</label>
                <ul>
                    <li>
                        <a href='https://docs.one4pay.com/#introduction' className='upper_footer_link'>Documentation</a>
                    </li>
                </ul>
            </div>
            <div className='upper_footer_section'>
                <label className='upper_footer_link'>Pricing</label>
                <ul>
                    <li>
                        <a href='/pricing' className='upper_footer_link'>Pricing</a>
                    </li>
                    <li>
                        <a href='/contact-sales' className='upper_footer_link'>Custom package</a>
                    </li>
                </ul>
            </div>
        </div>
        <div className='down_header_outer'>
            <img src={PCI} alt='pci_icon' className='pci_icon'/>
            <div className='down_footer'>
                <label>© One4pay 2022 All Rights Reserved.</label>
            </div>
        </div>
    </footer>
  )
}

export default Footer