import React from 'react'
import './FriendlySection.css'
import AddCardComponent from './addCardComponent'
// import LeftSideImg from './leftSideImg/LeftSideImg'
import BC from '../../assets/line.svg'
import circles from '../../assets/Circles.svg'
const FriendlySection = () => {
  return (
    <div className='friendly_section'>
      <div className='left_side_friendly'>
        <AddCardComponent/>
        <img src={BC} alt='background' className='try_yourself'/>
      </div>
      <div className='right_side_friendly' style={{backgroundSize:'contain', backgroundPosition:'right', height:'100%', position:'relative'}}>
        <div className='text'>
            <h1>User friendly.</h1>
            <label>
            Designed to be easy for an untrained user to use
            </label>
        </div>
        <img src={circles} alt='background' className='circles'/>
      </div>
      
    </div>
  )
}

export default FriendlySection