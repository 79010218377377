import React, {useState, useRef} from 'react'
import './ContactSales.css'
import Icon from '../homePage/homePageComponents/Banner/backgroundWave'
import CountryInput from './countryInput'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import emailjs from '@emailjs/browser';
import LoadingModal from '../localComponents/LoadingModal'
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const ContactSales = () => {
    const form = useRef();
    const [loadingModalShow, setLoadingModalShow] = useState(false);
    const bounce = cssTransition({
        enter: "animate__animated animate__bounceIn",
        exit: "animate__animated animate__bounceOut"
    });
    toast.configure({
        limit: 3,
        transition: bounce,
        autoClose: 1000,
    });
    const [contactSalesForm, setContactSalesForm] = useState({
        name: '',
        companyName: '',
        workEmail: '',
        country: '',
        companyWebsite: '',
        companySize: '',
        paymentVolume: '',
        workPhone: '',
    })
    const contactSales = (e) => {
        setLoadingModalShow(true)
        e.preventDefault();
        emailjs.sendForm('service_cuswneo', 'template_lkgpyla', form.current, 'rtL7NzhUN8Q1rzvJW')
      .then((result) => {
        setLoadingModalShow(false)
        toast.info('Sent!')
      }, (error) => {
        setLoadingModalShow(false)
        toast.error("Something went wrong...!")
      });
  };
    
  return (
    <div className='contact_sales'>
        <div style={{zIndex:'101',height:'80vh', backgroundColor:'#fff', clipPath: 'polygon(0% 0%, 100% 0%, 0% 100%, 0% 0%)', position:'absolute', top:'0', left:'0', right:'0'}}></div>
        <Icon notAbs={true}/>
        <form ref={form} className='contactSales_form' onSubmit={contactSales}>
            <div className='form_body'>
            <h1>Contact Sales</h1>
                <div className='form_group'>
                    <label>Name</label>
                    <input type='text' name='name' value={contactSalesForm.name} onChange={(e)=> setContactSalesForm({...contactSalesForm, name: e.target.value})} required/>
                </div>
                <div className='form_group'>
                    <label>Company Name</label>
                    <input type='text' name='companyName' value={contactSalesForm.companyName} onChange={(e)=> setContactSalesForm({...contactSalesForm, companyName: e.target.value})} required/>
                </div>
                <div className='form_group'>
                    <label>Work Email</label>
                    <input type='email' name='workEmail' value={contactSalesForm.workEmail} onChange={(e)=> setContactSalesForm({...contactSalesForm, workEmail: e.target.value})} required/>
                </div>
                <div className='form_group'>
                    <label>Country</label>
                    <CountryInput setCountry={setContactSalesForm} contactSalesForm={contactSalesForm} required/>
                </div>
                <div className='form_group'>
                    <label>Company Website</label>
                    <input type='text' name='companyWebsite' value={contactSalesForm.companyWebsite} onChange={(e)=> setContactSalesForm({...contactSalesForm, companyWebsite: e.target.value})} required/>
                </div>
                <div className='form_group'>
                    <label>Company Size</label>
                    <select style={{width:'100%'}} value={contactSalesForm.companySize} onChange={(e)=> setContactSalesForm({...contactSalesForm, companySize: e.target.value})} id="companySize" name="companySize" required>
                        <option defaultValue disabled value="">Select Company Size</option>
                        <option value="1-99">1-99</option>
                        <option value="100-999">1-999</option>
                        <option value="1000-4999">1000-4999</option>
                        <option value="5000+">5000+</option>
                    </select>
                </div>
                <div className='form_group'>
                    <label>Payment Volume</label>
                    <select style={{width:'100%'}} value={contactSalesForm.paymentVolume} onChange={(e)=> setContactSalesForm({...contactSalesForm, paymentVolume: e.target.value})} id="paymentVolume" name="paymentVolume" required>
                        <option defaultValue disabled value="">Select Payment Volume</option>
                        <option value="None, just getting started">None, just getting started</option>
                        <option value="Less than AED50,000">Less than AED50,000</option>
                        <option value="AED50,000 to AED150,000">AED50,000 to AED150,000</option>
                        <option value="AED150,000 to AED1,000,000">AED150,000 to AED1,000,000</option>
                        <option value="AED1,000,000 to AED5,000,000">AED1,000,000 to AED5,000,000</option>
                        <option value="More than AED5,000,000">More than AED5,000,000</option>
                    </select>
                </div>
                <div className='form_group'>
                    <label>Work Phone</label>
                    <PhoneInput
                        defaultCountry= 'AE'
                        name='workPhone'
                        value={contactSalesForm.workPhone}
                        onChange={(phone)=> setContactSalesForm({...contactSalesForm, workPhone: phone})}
                        required
                        id="phone"
                    />
                </div>
                <button type='submit'>
                    Contact
                </button>
                <ToastContainer closeButton={false} />
            </div>
        </form>
        <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </div>
  )
}

export default ContactSales