import React from 'react'
import './PaymentSolutions.css'
import paymentSolutionsImg2 from '../../../assets/section3Img.png'
import { AnimationOnScroll } from 'react-animation-on-scroll';

const PaymentSolutions = () => {
  return (
    <section className='payment_solutions'>
          <AnimationOnScroll delay={200} style={{width:'100%'}} animateIn="animate__slideInLeft"  animateOnce={true}>
            <img src={paymentSolutionsImg2} alt='payment_solutions_section_img' className='payment_solutions_img'/>
          </AnimationOnScroll>
          <AnimationOnScroll delay={300} style={{width:'100%'}} animateIn="animate__fadeIn"  animateOnce={true}>
            <div className='payment_solutions_content_wraper'>
                <h1>Payment Solutions</h1>
                <p>
                    The one-stop solution for accepting payments online.
                    Widest range of payment options - Credit & Debit cards. PCI DSS certified Security.  
                    One4pay is a solution that provides Scalability with Reliability. 

                    The list of best in-class features goes on as we improve continuously.
                </p>
            </div>
        </AnimationOnScroll>
    </section>
  )
}

export default PaymentSolutions