import React, {useState} from 'react'
import './buttons.scss'
import btnIcon from '../../assets/arrow_icon.svg'
import btnIconHovering from '../../assets/arrow_iconHovering.svg'
const BlueButton = (props) => {
    const [arrowSrc, setArrowSrc] = useState(btnIcon)

  return (
    <button type={props.btnType? props.btnType:'button'} onClick={()=> props.btnBeh==='create'? window.open('https://dashboard.one4pay.com/account/register'):null} style={{width: props.width? props.width:null}} className='blueButtonWraper' onMouseOver={()=> setArrowSrc(btnIconHovering)} onMouseOut={()=> setArrowSrc(btnIcon)}>
        <span style={{zIndex:'10'}}>{props.title}</span>
        <img style={{zIndex:'10'}} src={arrowSrc} alt='btn_background'/>
    </button>
  )
}

export default BlueButton