import React from "react";
import './DeveloperFriendly.scss'
import 'motion-css-animation/src/else/orbit.css'
import orbit1 from '../../../assets/orbit1Icon1.svg'
import orbit2 from '../../../assets/orbit1Icon2.svg'
import orbit3 from '../../../assets/orbit1Icon3.svg'
import codeBlocImg from '../../../assets/codeBlock.svg'

import orbit4 from '../../../assets/orbit2Icon1.svg'
import orbit5 from '../../../assets/orbit2Icon2.svg'
function CodeUniverse() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className='universe'
      viewBox="0 0 1034 978"
    >
      <g transform="translate(-860 -1990)">
        <g
          fill="none"
          stroke="#97ccfb"
          strokeDasharray="10"
          strokeWidth="1"
          transform="translate(1059 996)"
        >
          <g  data-name="Ellipse 8" transform="translate(-143 994)">
            <circle  cx="489" cy="489" r="488.5"></circle>
          </g>
          <g data-name="Ellipse 9" transform="translate(-67 1070)">
            <circle cx="413" cy="413" r="412.5"></circle>
          </g>
          <g data-name="Ellipse 10" transform="translate(14 1151)">
            <circle cx="332" cy="332" r="331.5"></circle>
          </g>
        </g>
      </g>
      <image className='animation orbit delay-10' href={orbit1} x='230' y='10' height="15%" width="120"/>
      <image className='animation orbit delay-5' href={orbit2} x='940' y='600' height="15%" width="120"/>
      <image className='animation orbit delay-0' href={orbit3} x='0' y='415' height="15%" width="120"/>
      <image href={codeBlocImg} x='130' y='100' height="80%" width="80%"/>
      <image className='animation orbit delay-15' href={orbit4} x='750' y='100' height="15%" width="120"/>
      <image className='animation orbit delay-20' href={orbit5} x='220' y='725' height="15%" width="120"/>

    </svg>
  );
}

export default CodeUniverse;

