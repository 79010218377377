import React, {useState} from 'react'
import './buttons.scss'
import btnIcon from '../../assets/arrow_icon.svg'
import btnIconHovering from '../../assets/arrow_iconHovering.svg'
const WhiteButton = (props) => {
    const [arrowSrc, setArrowSrc] = useState(btnIconHovering)

  return (
    <button onClick={()=> props.btnBeh==='create'? window.open('https://dashboard.one4pay.com/account/register'):null} className='whiteButtonWraper' onMouseOver={()=> setArrowSrc(btnIcon)} onMouseOut={()=> setArrowSrc(btnIconHovering)}>
        <span style={{zIndex:'10'}}>{props.title}</span>
        <img style={{zIndex:'10'}} src={arrowSrc} alt='btn_background' className='btn_icon'/>
    </button>
  )
}

export default WhiteButton