import React, {useState} from 'react'
import './pricingBanner.css'
import Icon from '../../homePage/homePageComponents/Banner/backgroundWave'
import pricingBannerBackground from '../../assets/newPricingBN.png'
import PaymentSection from '../../homePage/homePageComponents/paymentSection/PaymentSection'
import arrowPricing from '../../assets/arrow_pricing.svg'
import btnIcon from '../../assets/arrow_icon.svg'
import btnIconHovering from '../../assets/arrow_iconHovering.svg'
import pricing_banner_BC from '../../assets/pricing_body.svg'

const PricingBanner = () => {
    const [arrowSrc, setArrowSrc] = useState(btnIcon)
    // const [scrolled, setScrolled] = useState(btnIcon)

    // useEffect(()=> {
    //     function heightFunx() {
    //       setScrolled(window.pageYOffset) 
    //     }
    //     window.addEventListener('scroll', heightFunx)
    //     return () => window.removeEventListener("scroll", heightFunx);
    //   }, [scrolled])
    
    //   useEffect(()=> {
    //     console.log(scrolled)
    //     if (scrolled > 55) {
    //       setFillpee(true)
    //     } else {
    //       setFillpee(false)
    //     }
    //   }, [scrolled])
  return (
    <section className='pricing_banner'>
        <Icon/>
        <img src={pricingBannerBackground} alt='pricing_banner' className='pricing_banner_background'/>
        <div className='pricing_details_wraper'>
            <div className='pricing_text_wraper'>
                <h1>Pricing</h1>
                <label>Online Payment</label>
            </div>
            <div className='pricing_details_box_wraper'>
                <div className='pricing_details_box'>
                    <label className='title_of_the_box'>Online Payment</label>
                    <div className='pricing_details_box_numbers'>
                        <label className='bolded_label'>1.2 + </label>
                        <label className='not_bolded_label'>SAR</label>
                        <label className='bolded_label'>1.00</label>
                    </div>
                    <label className='title_of_the_box'>Per Successful Transaction</label>
                    <PaymentSection pricing={true}/>
                    <div className='pricing_box_list'>
                        <div className='pricing_box_list_item'>
                            <img src={arrowPricing} alt='arrow_pricing' className='arrow_pricing'/>
                            <label>No setup, hidden fees</label>
                        </div>
                        <div className='pricing_box_list_item'>
                            <img src={arrowPricing} alt='arrow_pricing' className='arrow_pricing'/>
                            <label>Secure payments</label>
                        </div>
                        <div className='pricing_box_list_item'>
                            <img src={arrowPricing} alt='arrow_pricing' className='arrow_pricing'/>
                            <label>Manage all your payments with advanced dashboard</label>
                        </div>
                    </div>
                    <button onClick={()=> window.open('https://dashboard.one4pay.com/account/register')} onMouseOver={()=> setArrowSrc(btnIconHovering)} onMouseOut={()=> setArrowSrc(btnIcon)} className='pricing_button'>
                        <span>Start Now</span>
                        <img style={{zIndex:'10'}} src={arrowSrc} alt='btn_background'/>
                    </button>
                </div>
                <img src={pricing_banner_BC} alt='pricing_banner_bc' className='pricing_banner_bc'/>

            </div>
        </div>
        {/* <img src={pricing_banner_BC} alt='pricing_banner_bc' className='pricing_banner_bc'/> */}
    </section>
  )
}

export default PricingBanner