import React, {useState, useEffect, useRef} from 'react'
import './letsGetStarted.css'
import envlop from '../../../assets/envlope.svg'
import envlopMob from '../../../assets/mobileEnvlop.svg'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import WhiteButton from '../../../localComponents/buttons/whiteButton'
import BlueButton from '../../../localComponents/buttons/blueButton'

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
import emailjs from '@emailjs/browser';
import LoadingModal from '../../../localComponents/LoadingModal';
import { ToastContainer, toast, cssTransition } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const LetsGetStarted = () => {
    const form = useRef();
    const [loadingModalShow, setLoadingModalShow] = useState(false);
    const bounce = cssTransition({
        enter: "animate__animated animate__bounceIn",
        exit: "animate__animated animate__bounceOut"
    });
    toast.configure({
        limit: 3,
        transition: bounce,
        autoClose: 1000,
    });
    const contactSales = (e) => {
        e.preventDefault();
        setLoadingModalShow(true)
        emailjs.sendForm('service_cuswneo', 'template_lkgpyla', form.current, 'rtL7NzhUN8Q1rzvJW')
      .then((result) => {
        setLoadingModalShow(false)
        toast.info('Sent!')
      }, (error) => {
        setLoadingModalShow(false)
        toast.error("Something went wrong...!")
      });
  };

    const [width, setWidth] = useState(window.innerWidth);

    const [contactForm, setContactForm] = useState({
        name: '',
        workPhone: '',
        companyName: '',
        else: ''
    })
    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
  return (
    <AnimationOnScroll delay={100} style={{width:'100%'}} animateIn="animate__slideInUp"  animateOnce={true}>
    <section className='lets_get_started'>
        <div className='right_side payment_solutions_content_wraper'>
            <h1>Let’s get started.</h1>
            <label>
                create an account with On4pay and start accepting payment.
            </label>
            <WhiteButton btnBeh='create' title='Create an Acount'/>
        </div>
        <hr className='hr_mob'/>
        <div className='form_section_wraper'>
            <img src={width> 580? envlop:envlopMob} alt='envlop' className='envlop_img'/>
            <form ref={form} className='get_started_form' onSubmit={contactSales}>
                <div className='first_part_form'>
                    <div className='form_input_wraper'>
                        <label>Name</label>
                        <input required type='text' value={contactForm.name} onChange={(e)=> setContactForm({...contactForm, name: e.target.value})}/>
                    </div>
                    <div className='form_input_wraper'>
                        <label>Contact Number</label>
                        <PhoneInput
                        defaultCountry= 'AE'
                        name='workPhone'
                        value={contactForm.workPhone}
                        onChange={(phone)=> setContactForm({...contactForm, workPhone: phone})}
                        required
                        id="phone"
                    />                    </div>
                    <div className='form_input_wraper'>
                        <label>Company Name</label>
                        <input required type='text' value={contactForm.companyName} onChange={(e)=> setContactForm({...contactForm, companyName: e.target.value})}/>
                    </div>
                </div>
                <div className='second_part_form'>
                    <div className='form_input_wraper'>
                        <label>Anything else?</label>
                        <textarea required value={contactForm.else} onChange={(e)=> setContactForm({...contactForm, else: e.target.value})}/>
                    </div>
                    <BlueButton btnType='submit' title='Send Message'/>
                    <ToastContainer closeButton={false} />
                </div>
            </form>
        </div>
    </section>
    <LoadingModal
        show={loadingModalShow}
        dialogClassName="modal-60w"
        backdrop="static"
      />
    </AnimationOnScroll>
  )
}

export default LetsGetStarted