import React, {useState} from 'react'
import './WIncluded.css'
import onlinePayment from '../../assets/Online-Payment.gif'
import paymentLink from '../../assets/Payment-Link.gif'
import preBuilt from '../../assets/Pre-Built-Checkout.gif'
import pricingBody from '../../assets/Invoicing.gif'
import tokenization from '../../assets/Tokenization.gif'

import onlinePaymentPNG from '../../assets/Online-Payment.png'
import paymentLinkPNG from '../../assets/Payment-Link.png'
import preBuiltPNG from '../../assets/Pre-Built-Checkout.png'
import pricingBodyPNG from '../../assets/Invoicing.png'
import tokenizationPNG from '../../assets/Tokenization.png'
const WIncluded = () => {
    const [gifHovering, setGifHovering] = useState({index: 0, open: false})

  return (
    <section className='whats_included'>
        <h1>What’s included?</h1>
        <div className='features_included'>
            <div onMouseOut={() => setGifHovering({...gifHovering, index: 0, open: false})} onMouseOver={() => setGifHovering({...gifHovering, index: 1, open: true})} style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                <div className='feature_element'>
                    <img src={gifHovering.index===1 && gifHovering.open? onlinePayment:onlinePaymentPNG} alt='feature_icon' className='feature_icon'/>
                    <h6 className='feature_title'>Online Payments</h6>
                    <p>Acceptance of electronic payment for online transactions</p>
                </div>
            </div>

            <div onMouseOut={() => setGifHovering({...gifHovering, index: 0, open: false})} onMouseOver={() => setGifHovering({...gifHovering, index: 2, open: true})} style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
            <div className='feature_element'>
                <img src={gifHovering.index===2 && gifHovering.open? paymentLink:paymentLinkPNG} alt='feature_icon' className='feature_icon'/>
                <h6 className='feature_title'>Payment Links</h6>
                <p>URL or QR code that takes customers to a stand-alone web page that can be created in a few minutes</p>
            </div>
            </div>

            <div onMouseOut={() => setGifHovering({...gifHovering, index: 0, open: false})} onMouseOver={() => setGifHovering({...gifHovering, index: 3, open: true})} style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                <div className='feature_element'>
                    <img src={gifHovering.index===3 && gifHovering.open? pricingBody:pricingBodyPNG} alt='feature_icon' className='feature_icon'/>
                    <h6 className='feature_title'>Invoicing</h6>
                    <p>online invoice allows customers to both view an invoice and pay through an online portal in one easy step.</p>
                </div>
            </div>

            <div onMouseOut={() => setGifHovering({...gifHovering, index: 0, open: false})} onMouseOver={() => setGifHovering({...gifHovering, index: 4, open: true})} style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                <div className='feature_element'>
                    <img src={gifHovering.index===4 && gifHovering.open? preBuilt:preBuiltPNG} alt='feature_icon' className='feature_icon'/>
                    <h6 className='feature_title'>Pre-Built Checkout</h6>
                    <p>A hosted payment page optimized for conversion</p>
                </div>
            </div>

            <div onMouseOut={() => setGifHovering({...gifHovering, index: 0, open: false})} onMouseOver={() => setGifHovering({...gifHovering, index: 5, open: true})} style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                <div className='feature_element'>
                    <img src={gifHovering.index===5 && gifHovering.open? onlinePayment:onlinePaymentPNG} alt='feature_icon' className='feature_icon'/>
                    <h6 className='feature_title'>Customizable UI</h6>
                    <p>Customize the look and feel of Checkout in the One4pay Dashboard</p>
                </div>
            </div>
            <div onMouseOut={() => setGifHovering({...gifHovering, index: 0, open: false})} onMouseOver={() => setGifHovering({...gifHovering, index: 6, open: true})} style={{display:'flex', alignItems:'center', justifyContent:'center', width:'100%'}}>
                <div className='feature_element'>
                    <img src={gifHovering.index===6 && gifHovering.open? tokenization:tokenizationPNG} alt='feature_icon' className='feature_icon'/>
                    <h6 className='feature_title'>Tokenization</h6>
                    <p>The payments in which the card details is substituted by a token while performing a payment transaction</p>
                </div>
            </div>
            
        </div>
    </section>
  )
}

export default WIncluded