import './App.css';
// import { Route, Switch } from "react-router-dom";
import Header from './components/localComponents/header/Header';
import HomePage from './components/homePage/HomePage';
import Footer from './components/localComponents/footer/Footer';
import { Route, Routes } from "react-router-dom";
import Pricing from './components/pricingPage/Pricing';
import Developers from './components/developersPage/Developers';
import ContactSales from './components/contactSales/ContactSales';

function App() {
  return (
    <div className="App">
        <Header/>
        <div className="pages_Wraper">
            <Routes>
                <Route path="/" element={<HomePage/>} exact />
                <Route path="/pricing" element={<Pricing/>} exact />
                <Route path="/online-payments" element={<Developers/>} exact />
                <Route path="/contact-sales" element={<ContactSales/>} exact />
            </Routes>
        </div>
      <Footer/>
    </div>
  );
}

export default App;
