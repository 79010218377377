import React from 'react'
import './HomePage.css'
import Banner from './homePageComponents/Banner/Banner'
import PaymentSection from './homePageComponents/paymentSection/PaymentSection'
import PaymentSolutions from './homePageComponents/paymentSolutions/PaymentSolutions'
import DeveloperFriendly from './homePageComponents/developerFriendly/DeveloperFriendly'
import LetsGetStarted from './homePageComponents/letsGetStarted/letsGetStarted'
const HomePage = () => {
  return (
    <div className='Home_page'>
        <Banner/>
        <PaymentSection/>
        <PaymentSolutions/>
        <DeveloperFriendly/>
        <LetsGetStarted/>
    </div>
  )
}

export default HomePage