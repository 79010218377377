import React, {useState, useEffect} from 'react'
import './Growth.css'
import btnIcon from '../../assets/arrow_icon.svg'
import SectionImage from './sectionImgComp/sectionImage'
import btnIconHovering from '../../assets/arrow_iconHovering.svg'
import blackArrow from '../../assets/blackArrow.svg'
import {useNavigate} from 'react-router-dom'

const Growth = () => {
    const [blueBtn, setBlueBtn] = useState(btnIcon)
    const [blackBtn, setBlackBtn] = useState(blackArrow)
    const [width, setWidth] = useState(window.innerWidth);
    const navigate = useNavigate()

    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
  return (
    <div className='growth'>
        <div className='blueBC'></div>
        <div className='growth_content'>
            <h1>A complete payments platform, engineered for growth</h1>
            <label style={{fontSize:'15px'}}>
                <span style={{fontWeight:'900'}}>One4pay</span> is a payment service provider PCI DSS Certified. 
                One4pay brining new standards to the payment gateway industry in the 
                <span style={{fontWeight:'900'}}> Middle East</span> and <span style={{fontWeight:'900'}}>North Africa</span> region.
            </label>
            <div className='banner_btns'>
                <button onClick={()=> window.open('https://dashboard.one4pay.com/account/register')} onMouseOver={()=> width>900? setBlueBtn(blackArrow):null} onMouseOut={()=> setBlueBtn(btnIcon)} className='start_now_btn_inner' style={{width:'150px', height:'48px', cursor:'pointer'}}>
                    <span style={{zIndex:'10'}}>Start Now</span>
                    <img style={{zIndex:'10'}} src={blueBtn} alt='btn_background' className='btn_icon'/>
                </button>
                <button onClick={()=> navigate('/pricing')} onMouseOver={()=> width>900? setBlackBtn(btnIconHovering):null} onMouseOut={()=> setBlackBtn(blackArrow)} className='pricing_btn_black' style={{width:'150px', height:'48px', cursor:'pointer'}}>
                    <span style={{zIndex:'10'}}>Pricing</span>
                    <img style={{zIndex:'10'}} src={blackBtn} alt='btn_background' className='btn_icon'/>
                </button>
            </div> 
        </div>
        <div className='image_wraper' style={{display:'flex', flexDirection:'column', alignItems:'center', width:'100%'}}>
            <h1 style={{display:'none'}}>A complete payments platform, engineered for growth</h1>
            <SectionImage/>
        </div>
        
    </div>
  )
}

export default Growth