import React from 'react'
import './DeveloperFriendly.scss'
// import CodeBlock from './codeBlock/CodeBlock'
import BlueButton from '../../../localComponents/buttons/blueButton'
import CodeUniverse from './codeUnevirse'
import { AnimationOnScroll } from 'react-animation-on-scroll';

const DeveloperFriendly = () => {
  return (
    <section className='developer_friendly'>
      <AnimationOnScroll delay={300} style={{width:'100%'}} animateIn="animate__fadeIn"  animateOnce={true}>
        <div className='right_side payment_solutions_content_wraper'>
            <h1>Developer Friendly</h1>
            <p>
                With a clear understanding of the market from multiple ends, Merchant side, Developers and connectors. One4Pay provides a swift exquisite payment solution. Fast integrations and customizations, banks, merchants and APMs can be integrated within a week
            </p>
            <BlueButton btnBeh={'create'} title='Create an Acount'/>
        </div>
        </AnimationOnScroll>
        <div style={{width:'100%', height:"100%", position:'relative', padding:'10px'}}>
            <CodeUniverse/>
            {/* <CodeBlock code={'hi'} language='text'/> */}
        </div>
    </section>
  )
}

export default DeveloperFriendly