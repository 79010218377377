import React, {useState, useEffect} from 'react'
import './PaymentSection.css'
import applePay from '../../../assets/icons payment/apple-pay coloured.svg'
import applePayNotColored from '../../../assets/icons payment/apple-pay.svg'
import { AnimationOnScroll } from 'react-animation-on-scroll';

import masterCard from '../../../assets/icons payment/Mastercard-logo.svg'
import visa from '../../../assets/icons payment/visa.svg'
import stcPayment from '../../../assets/icons payment/208197.svg'
import madaPayment from '../../../assets/icons payment/366974.svg'

const PaymentSection = (props) => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
          setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
      }, [width]);
    const [applePaySrc, setApplePaySrc] = useState(applePayNotColored)
  return (
    <AnimationOnScroll style={{width:'100%'}} animateIn="animate__fadeInUpBig"  animateOnce={true}>
    <section className='payment_section' style={{minHeight: props.pricing? 'unset':null, padding: props.pricing? width>900? '25px':'5px':null, display: props.pricing? width<=357? 'grid':null:null, gridTemplateColumns: props.pricing? width<=357? 'auto auto':null:null}}>
        <AnimationOnScroll delay={800} style={{width:'100%'}} animateIn="animate__slideInUp"  animateOnce={true}>
            <img style={{height: props.pricing? width>900? '60px':'40px':null, width: props.pricing? width>900? '60px':'40px':null}} src={masterCard} alt='masterCard' className='payment_icons'/>
        </AnimationOnScroll>
        <AnimationOnScroll delay={1000} style={{width:'100%'}} animateIn="animate__slideInUp"  animateOnce={true}>
            <img style={{height: props.pricing? width>900? '60px':'40px':null, width: props.pricing? width>900? '60px':'40px':null}} src={visa} alt='masterCard' className='payment_icons'/>
        </AnimationOnScroll>
        <AnimationOnScroll delay={1200} style={{width:'100%'}} animateIn="animate__slideInUp"  animateOnce={true}>
            <img style={{height: props.pricing? width>900? '60px':'40px':null, width: props.pricing? width>900? '60px':'40px':null}} src={stcPayment} alt='masterCard' className='payment_icons'/>
        </AnimationOnScroll>
        <AnimationOnScroll delay={1300} style={{width:'100%'}} animateIn="animate__slideInUp"  animateOnce={true}>
            <img style={{height: props.pricing? width>900? '60px':'40px':null, width: props.pricing? width>900? '60px':'40px':null}} src={madaPayment} alt='masterCard' className='payment_icons'/>
        </AnimationOnScroll>
        <AnimationOnScroll delay={1400} style={{width:'100%'}} animateIn="animate__slideInUp"  animateOnce={true}>
            <img style={{height: props.pricing? width>900? '60px':'40px':null, width: props.pricing? width>900? '60px':'40px':null}} src={applePaySrc} onMouseOver={()=> setApplePaySrc(applePay)} onMouseOut={()=> setApplePaySrc(applePayNotColored)} alt='masterCard' className='payment_icons'/>
        </AnimationOnScroll>
    </section>
    </AnimationOnScroll>
  )
}

export default PaymentSection