import React from 'react'
import './EasySection.css'
import circles from '../../assets/lastSection.svg'

const EasySection = () => {
  return (
    <div className='easySection'>
      <div className='left_side_easySection'>
      <div className='text_easy'>
            <h1>Easy and quick onboarding.</h1>
            <label>
                Register your company with us and start accepting online payments. No setup or hidden fees. Manage all your transactions through a user friendly dashboard.
            </label>
        </div>
      </div>
        <img src={circles} alt='background' className='last_section'/>
      
    </div>
  )
}

export default EasySection