import { slide as Menu } from "react-burger-menu";
import React, {useState} from "react";
import MenuIcon from "../../../assets/menu.png";
import MenuBlueIcon from "../../../assets/menuBlue.png";
import {useNavigate } from "react-router-dom";
import "./SideMenu.css";
function SideMenu(props) {
  const [openPricing, setOpenPricing] = useState(false)
  const [openProducts, setOpenProducts] = useState(false)

  var styles = {
    bmBurgerButton: {
      position: 'fixed',
      width: '30px',
      height: '25px',
      left: '25px',
      right: 'auto',
      transform: 'unset',
      top: '15px',
    },
    bmMenuWrap: {
      height:'100vh',
      position:'fixed',
      top:'0',
      left: '0',
    }
  }
  const navigate = useNavigate()
  return (
    <Menu
      styles={styles}
      className="naviForNavi"
      customBurgerIcon={<img src={props.scrolled? MenuBlueIcon: MenuIcon} alt="mobile_menu" />}
    >
      <label style={{ textAlign: "center" }}>Menu</label>
      <div
      className='sideMenu_links'
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "5px",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <button
          className="menu_item"
          onClick={()=> navigate('/')}
        >
          Home
        </button>
        <button style={{display:'flex', alignItems:'center', justifyContent:'space-between'}} onClick={()=>  setOpenProducts(!openProducts)} className="menu_item">
          <span>Products</span>
          {!openProducts?
          <i className="fas fa-chevron-right"></i> : <i className="fas fa-chevron-down"></i>
        }
        </button>
        {openProducts?
        <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start', gap:'5px', paddingLeft: '5px'}}>
          <button onClick={()=> navigate('/online-payments')} className='sub_menu_item'>Online Payments</button>
          {/* <button onClick={()=> navigate('/')} className='sub_menu_item'>Point of Sale (POS)</button> */}
        </div>:null
      }
        
        <button
        style={{display:'flex', alignItems:'center', justifyContent:'space-between'}}
          className="menu_item"
          onClick={()=> setOpenPricing(!openPricing)}
        >
           <span>Pricing</span>
          {!openPricing?
          <i className="fas fa-chevron-right"></i> : <i className="fas fa-chevron-down"></i>
        }
        
        </button>
        {openPricing?
        <div style={{display:'flex', flexDirection:'column', alignItems:'flex-start', justifyContent:'flex-start', gap:'5px', paddingLeft: '5px'}}>
          <button onClick={()=> navigate('/pricing')} className='sub_menu_item'>Pricing</button>
          <button onClick={()=> navigate('/contact-sales')} className='sub_menu_item'>Custom package</button>
        </div>:null
      }
      <button
          className="menu_item"
          onClick={()=> window.open('https://docs.one4pay.com/#introduction')}
        >
          Developers
        </button>
        <button
          className="menu_item getStarted_btn_mob"
        >
          Get Started
        </button>
      </div>
    </Menu>
  );
}
export default SideMenu;
