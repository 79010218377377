import React from 'react'
import './Pricing.css'
import PricingBanner from './pricingBanner/pricingBanner'
import WIncluded from './what\'sIncluded/WIncluded'
const Pricing = () => {
  return (
    <div className='pricing_page'>
      <PricingBanner/>
      <WIncluded/>
    </div>
  )
}
export default Pricing