import React, {useState, useEffect} from "react";

function Loading(props) {
    const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
      function handleResize() {
        setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, [width]);
    return (
        <div 
            className='Spinner'
            style={{ 
                width: props.width? props.width:'100%',
                display: 'flex',
                alignItems: 'center', justifyContent: 'center',
                backgroundColor: props.backgroud? props.backgroud:'transparent',
                borderRadius: props.borderR? props.borderR:'unset',
                height: props.height? props.mediaQ? width<=900? props.mediaQ:props.height: props.height:'300px'
            }}
        >
            <div
                className={`spinner-border ${props.color==='white'? 'text-light':'text-danger'}`}
                role="status" 
                style= {{height: props.small? props.small: '3rem', width: props.small? props.small: '3rem'}}
            >
            </div>
        </div>
    );
}
export default Loading;
