import React from 'react'
import './Developers.css'
import Growth from './growth/Growth'
import FriendlySection from './friendly/FriendlySection'
import EasySection from './easy/EasySection'

const Developers = () => {
  return (
    <div className='developers_page'>
        <Growth/>
        <FriendlySection/>
        <EasySection/>
    </div>
  )
}

export default Developers