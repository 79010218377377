import React, {useState, useEffect} from 'react'
import './Header.css'
import one4PayLogo from '../../assets/logo.svg'
import one4PayLogoBlack from '../../assets/logoBlack.svg'
import {useNavigate} from 'react-router-dom'
import SideMenu from './sideMenu/SideMenu'
import 'animate.css';
import {useLocation} from "react-router-dom";

const Header = () => {
    const [width, setWidth] = useState(window.innerWidth);
    const [scrolled, setScrolled] = useState(0);
    const [dropDownOnHover, setDropDownOnHover] = useState(false);
    const [dropDownOnHover2, setDropDownOnHover2] = useState(false);
    const location = useLocation()
    useEffect(() => {
        window.scrollTo(10, 0);
      }, [location]);
    
    const display = () => {
        if (window.scrollY >=59) {
          setScrolled(true)  
        } else {
            setScrolled(false)  
        }
    }
    window.addEventListener('scroll', display)
    // useEffect(() => {
    //     function handleResize() {
    //         setScroll(window.pageYOffset || document.documentElement.scrollTop);
    //     }
    //     window.addEventListener("scroll", handleResize);
    //     return () => window.removeEventListener("scroll", handleResize);
    //   }, [scroll]);
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
    const navigate = useNavigate()
  return (
      <React.Fragment>
          {width>850?
          <header style={{backgroundColor:scrolled? '#fff':location.pathname==='/online-payments'?'#fff':location.pathname==='/contact-sales'?'transparent': null}} className={scrolled? 'desktop_header animate__animated animate__slideInDown':'desktop_header'}>
        <div style={{cursor:'pointer'}} className='main_logo_wraper' onClick={()=> navigate('/')}>
           <img src={scrolled? one4PayLogoBlack:location.pathname==='/online-payments'?one4PayLogoBlack:location.pathname==='/contact-sales'?one4PayLogoBlack: one4PayLogo} alt='one_for_pay_logo' className='mainLogo'/> 
        </div>
        <ul className='main_nav'>
        <li>
            <button style={{color:scrolled? '#026fad':location.pathname==='/online-payments'?'#026fad':location.pathname==='/contact-sales'?'#026fad':null}} onClick={()=> navigate('/')} className={scrolled? 'main_nav_element forceStyle':location.pathname==='/online-payments'?'main_nav_element forceStyle':'main_nav_element softStyle'}>
                Home
            </button>
            </li>
            <li onMouseOut={()=> setDropDownOnHover(false)} onMouseOver={()=> setDropDownOnHover(true)}
                >
            <div className="dropdown">
                <button
                style={{
                    color:scrolled? '#026fad':location.pathname==='/online-payments'?'#026fad':location.pathname==='/contact-sales'?'#026fad':null}} type='button' className={scrolled? 'main_nav_element forceStyle':location.pathname==='/online-payments'?'main_nav_element forceStyle':location.pathname==='/contact-sales'?'main_nav_element forceStyle':'main_nav_element softStyle'}
                  id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false">
                    Products
                </button>
                <div
                style={{backgroundColor:scrolled? '#fff':location.pathname==='/online-payments'?'#fff':location.pathname==='/contact-sales'?'#fff': null, display:dropDownOnHover? 'block':'none'}}
                  className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a style={{color:scrolled? '#155A96':location.pathname==='/online-payments'?'#155A96':location.pathname==='/contact-sales'?'#155A96': null}} className="dropdown-item" href="/online-payments">Online Payments</a>
                  {/* <a style={{color:scrolled? '#155A96':location.pathname==='/online-payments'?'#155A96':location.pathname==='/contact-sales'?'#155A96': null}} className="dropdown-item" href="/online-payments">Point Of Sale</a> */}
                </div>
            </div>
            </li>
            
            <li onMouseOut={()=> setDropDownOnHover2(false)} onMouseOver={()=> setDropDownOnHover2(true)}>
            <div className="dropdown">
                <button
                style={{
                    color:scrolled? '#026fad':location.pathname==='/online-payments'?'#026fad':location.pathname==='/contact-sales'?'#026fad':null}} type='button' className={scrolled? 'main_nav_element forceStyle':location.pathname==='/online-payments'?'main_nav_element forceStyle':location.pathname==='/contact-sales'?'main_nav_element forceStyle':'main_nav_element softStyle'}
                  id="dropdownMenuButton" aria-haspopup="true" aria-expanded="false">
                    Pricing
                </button>
                <div
                style={{backgroundColor:scrolled? '#fff':location.pathname==='/online-payments'?'#fff':location.pathname==='/contact-sales'?'#fff': null, display:dropDownOnHover2? 'block':'none'}}
                  className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a style={{color:scrolled? '#155A96':location.pathname==='/online-payments'?'#155A96':location.pathname==='/contact-sales'?'#155A96': null}} className="dropdown-item" href="/pricing">Pricing</a>
                  <a style={{color:scrolled? '#155A96':location.pathname==='/online-payments'?'#155A96':location.pathname==='/contact-sales'?'#155A96': null}} className="dropdown-item" href="/contact-sales">Custom Package</a>
                </div>
            </div>
            </li>
            <li>
                <button style={{color:scrolled? '#026fad':location.pathname==='/online-payments'?'#026fad':location.pathname==='/contact-sales'?'#026fad':null}} onClick={()=> window.open('https://docs.one4pay.com/#introduction')} className={scrolled? 'main_nav_element forceStyle':location.pathname==='/online-payments'?'main_nav_element forceStyle':'main_nav_element softStyle'}>
                    Developers
                </button>
            </li>
        </ul>
        <div style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
        <button onClick={()=> window.open('https://dashboard.one4pay.com/account/register')} className={scrolled? 'getStarted_btn btnH btn-2 hover-filled-slide-down':location.pathname==='/online-payments'?'getStarted_btn btnH btn-2 hover-filled-slide-down':location.pathname==='/contact-sales'?'getStarted_btn btnH btn-2 hover-filled-slide-down':'getStarted_btn btnH btn-1 hover-filled-slide-down'}>
            <span>Get Started</span>
        </button>
        </div>
    </header>:
    <header style={{backgroundColor:scrolled? '#fff':location.pathname==='/online-payments'?'#fff':location.pathname==='/contact-sales'?'transparent':null}} className={scrolled? 'mobile_header animate__animated animate__slideInDown':'mobile_header'}>
        <SideMenu scrolled={scrolled? scrolled:location.pathname==='/online-payments'? true:location.pathname==='/contact-sales'?true:scrolled}/>
        <div style={{width:'100%', display:'flex', alignItems: 'center', justifyContent:'center', cursor:'pointer'}} onClick={()=> navigate('/')}>
            <img src={scrolled? one4PayLogoBlack:location.pathname==='/online-payments'?one4PayLogoBlack:location.pathname==='/contact-sales'?one4PayLogoBlack: one4PayLogo} alt='one_for_pay_logo' className='mainLogo'/> 
        </div>
        <div className='btn_header_mob_wraper' style={{width:'100%', display:'flex', alignItems: 'center', justifyContent:'flex-end'}}>
            <button className='btn_header_mob' style={{width:'auto', height:'35px', border:'none', color:scrolled? '#155A96':location.pathname==='/online-payments'?'#155A96':location.pathname==='/contact-sales'?'#155A96':'#fff', fontFamily: "'Poppins', sans-serif", backgroundColor:'transparent'}}>
                Get Started
            </button>
        </div>
    </header>
        }
      </React.Fragment>
    
  )
}

export default Header